<template>
  <layout title="Agenda" :options="options" :rotas="rotas">
    <v-sheet
      :dark="$theme.dark"
      :light="$theme.light"
      class="expande-horizontal sheet-container pt-2"
    >
      <v-flex xs12>
        <Calendar
          @create-event="date => $refs.eventDialog.open(date, 'create-event')"
          @open-event="event => $refs.eventDialog.open(event, 'open-event')"
          :events="get_external_movimentations"
        />
        <EventDialog ref="eventDialog" />
      </v-flex>
    </v-sheet>
  </layout>
</template>

<script>
import Calendar from "../components/Calendar.vue";
import EventDialog from "../components/EventDialog.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      rotas: [
        {
          name: "Início",
          path: "/dashboard"
        },
        {
          name: "Agenda",
          path: "/agenda"
        }
      ],
      options: []
    };
  },
  components: {
    Calendar,
    EventDialog
  },
  computed: {
    ...mapGetters([
      "get_external_movimentations_filtros",
      "getLoggedUser",
      "get_external_movimentation",
      "get_external_movimentations",
      "getPersonalizedLoading"
    ]),
    filtereds() {
      if (this.filter.search) {
        return this.get_external_movimentations.docs.filter(item => {
          return (
            (item.nome &&
              item.nome
                .toLowerCase()
                .includes(this.filter.search.toLowerCase())) ||
            (item.email &&
              item.email
                .toLowerCase()
                .includes(this.filter.search.toLowerCase())) ||
            (item.telefone &&
              item.telefone.includes(this.filter.search.toLowerCase()))
          );
        });
      } else {
        return this.get_external_movimentations.docs;
      }
    }
  },
  methods: {
    excluir_external_movimentation_dialog(item) {
      let text = "Deseja remover este external_movimentation?";
      this.createConfirmAction({
        message: text,
        icon: "mdi-close-circle",
        action: "excluir_external_movimentation",
        action_value: item
      });
    },
    getLinkById(id) {
      return this.getLinkById[id];
    },
    ...mapActions([
      "abre_modal_view_tenant",
      "listar_tenants",
      "listar_clientes",
      "listar_external_movimentations",
      "criar_estoque",
      "listar_external_movimentationcategorias",
      "criar_external_movimentation",
      "listar_estoques",
      "atualizar_external_movimentation",
      "setPersonalizedLoading",
      "createConfirmAction"
    ]),
    criarOuAtualizar() {
      if (this.get_external_movimentation._id) {
        this.atualizar_external_movimentation();
      } else {
        this.criar_external_movimentation();
      }
    },
    iniciarCadastro() {
      this.setPersonalizedLoading("animate__fadeOutTopRight");
      this.$store.commit("set_external_movimentation", { new: true });
      this.$store.commit("setLinks", []);
      this.setPersonalizedLoading("animate__fadeInUp");
    },
    createOrUpdate() {
      if (this.$refs.form.validate()) {
        if (this.get_external_movimentation._id) {
          this.atualizar_external_movimentation();
        } else {
          this.criar_external_movimentation();
        }
      }
    }
  },
  created() {
    this.listar_external_movimentations();
    this.listar_clientes();
  }
};
</script>

<style scoped>
.sheet-container {
  min-height: 95vh;
  max-height: 95vh;
  overflow: hidden;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.item-list {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #1173ff;
}
</style>
